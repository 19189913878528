import { sharedImages } from '../sharedImages'
import { returnAndEarnImages } from './images'

// Note! These values are only available runtime
export const config = {
  name: 'Return and Earn',
  showCountryPicker: false,
  gtagId: 'G-3QG48L4MPT',
  faviconPath: '/images/favicons/returnandearn.png',
  appLinks: {
    ios: 'https://itunes.apple.com/au/app/id1574701034',
    android: 'http://play.google.com/store/apps/details?id=com.tomra.digital.returnandearn'
  },
  termsOfService: 'https://tomracleanaway.com.au/returnandearn-app-terms-and-conditions',
  privacyPolicy: 'https://tomracleanaway.com.au/Privacy-Policy',
  faq: `${window.location.origin}/docs/returnandearn/faq.html`,
  images: {
    ...sharedImages,
    ...returnAndEarnImages
  },
  latestNativeAppVersion: {
    ios: '1.5',
    android: '1.6'
  },
  setThemeColors: () => {
    const root = document.documentElement

    root.style.setProperty('--colors-primary', 'rgb(134, 199 ,252)')
    root.style.setProperty('--colors-primary-light', 'rgb(167, 219, 198)')
    root.style.setProperty('--colors-secondary', 'rgb(0, 174, 119)')
    root.style.setProperty('--colors-secondary-light', 'rgb(167, 219, 198)')
    root.style.setProperty('--colors-tertiary', 'rgb(253, 187, 41)')
    root.style.setProperty('--colors-tertiary-light', 'rgb(248, 224, 175)')
    root.style.setProperty('--colors-button', 'rgb(0, 174, 119)')
  },
  achievements: {
    'ACHIEVEMENT_CUMULATIVE_ITEM_COUNT-1': 'Fledgling recycler',
    'ACHIEVEMENT_CUMULATIVE_ITEM_COUNT-2': 'Avid recycler',
    'ACHIEVEMENT_CUMULATIVE_ITEM_COUNT-3': 'Champion recycler',
    'ACHIEVEMENT_CUMULATIVE_ITEM_COUNT-4': 'Superstar recycler',
    'ACHIEVEMENT_UNIQUE_LOCATION_COUNT-1': 'Local legend',
    'ACHIEVEMENT_UNIQUE_LOCATION_COUNT-2': 'Suburb sampler',
    'ACHIEVEMENT_UNIQUE_LOCATION_COUNT-3': 'Regional explorer',
    'ACHIEVEMENT_UNIQUE_LOCATION_COUNT-4': 'NSW nomad',
    'ACHIEVEMENT_CUMULATIVE_DIGITAL_PAYOUT_AMOUNT-1': 'Earnest Earner',
    'ACHIEVEMENT_CUMULATIVE_DIGITAL_PAYOUT_AMOUNT-2': 'Earn-trepreneur',
    'ACHIEVEMENT_CUMULATIVE_DIGITAL_PAYOUT_AMOUNT-3': 'Moneybags',
    'ACHIEVEMENT_CUMULATIVE_DIGITAL_PAYOUT_AMOUNT-4': 'Richest recycler',
    'ACHIEVEMENT_CUMULATIVE_DIGITAL_VOUCHER_AMOUNT-1': 'Tree hugger',
    'ACHIEVEMENT_CUMULATIVE_DIGITAL_VOUCHER_AMOUNT-2': 'Bushland friend',
    'ACHIEVEMENT_CUMULATIVE_DIGITAL_VOUCHER_AMOUNT-3': 'Eco warrior',
    'ACHIEVEMENT_CUMULATIVE_DIGITAL_VOUCHER_AMOUNT-4': 'Earth hero',
    'ACHIEVEMENT_CUMULATIVE_DONATION_COUNT-1': 'Helping hand',
    'ACHIEVEMENT_CUMULATIVE_DONATION_COUNT-2': 'Goodhearted giver',
    'ACHIEVEMENT_CUMULATIVE_DONATION_COUNT-3': 'Dedicated donator',
    'ACHIEVEMENT_CUMULATIVE_DONATION_COUNT-4': 'Charitable champ'
  }
}
